@import "src/styles/vars"

.donation-details
  position: relative
  display: flex
  flex-direction: column
  height: 100%
  width: 600px
  padding: 16px
  background-color: $blue-ozone
  @include mq($until: tablet)
    width: 100%
  .header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    margin-left: 16px
    margin-right: 16px
    margin-top: 12px
    .title
      font-style: normal
      font-weight: 500
      font-size: 16px
      line-height: 22px
      display: flex
      align-items: center
      color: $blue-ocean
      padding-top: 4px
      padding-bottom: 4px
    .sub
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      text-align: right
      letter-spacing: 0.5px
      color: $blue-primary
      padding: 4px
      &:hover
        background-color: $blue-primary
        color: $white-snow
        border-radius: 10px
  .image-details-wait
    display: flex
    flex-direction: row
    margin-top: 20px
    .first
      display: flex
      padding: 16px
      background-color: $grey-bg
      justify-content: center
      align-items: center
      border-radius: 12px
      margin-right: 6px
      .soup
        width: 100px
    .second
      display: flex
      flex: 0.5
      margin-left: 6px
      height: 100%
      .title
        font-style: normal
        font-weight: 400
        font-size: 14px
        line-height: 18px
        letter-spacing: 0.5px
        color: $grey-text
  .image-details-ready
    display: flex
    flex-direction: column
    margin-top: 20px
    margin-left: 16px
    margin-right: 16px
    .image
      width: 100%
      height: 220px
      border-radius: 12px
    .title
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 18px
      display: flex
      align-items: center
      letter-spacing: 0.5px
      color: $blue-ocean
      margin-top: 10px
    .description
      font-style: normal
      font-weight: 400
      font-size: 12px
      line-height: 16px
      display: flex
      align-items: center
      letter-spacing: 0.5px
      color: $grey-text
      margin-top: 4px
  .content
    margin: 16px 0
    background-color: $white
    border-radius: 12px
    .row
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      padding: 12px 8px
      border-bottom-style: solid
      border-bottom-color: $grey-divider
      border-bottom-width: 0.5px
      &:nth-last-child(1)
        border-bottom: none
      .title
        font-style: normal
        font-weight: 400
        font-size: 15px
        line-height: 18px
        display: flex
        align-items: center
        letter-spacing: 0.5px
        color: $grey-text
      .sub
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 22px
        display: flex
        align-items: center
        text-align: right
        color: $blue-ocean
        &.link
          text-decoration: underline
          cursor: pointer