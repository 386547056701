@import "src/styles/vars"

.portfolio
  position: relative
  display: flex
  flex-direction: column
  height: 100%
  width: 600px
  padding: 16px
  background-color: $blue-ozone
  @include mq($until: tablet)
    width: 100%
  .content
    display: flex
    width: 100%
  .top-info-container
    display: flex
    flex-direction: column
    background-color: $white
    border-radius: 12px
    padding: 8px
    .top-info
      display: flex
      flex-direction: row
      .first
        display: flex
        flex: 0.5
        justify-content: center
        align-items: center
        margin-right: 6px
        .image
          width: 100%
          border-radius: 12px
          &.no-image
            background-color: $blue-light
            object-fit: contain
            padding: 10px
      .second
        display: flex
        flex-direction: column
        flex: 0.5
        margin-left: 6px
        height: 100%
        .title
          font-style: normal
          font-weight: 500
          font-size: 16px
          line-height: 16px
          display: flex
          align-items: center
          letter-spacing: 0.5px
          color: $blue-ocean
          margin-bottom: 4px
        .sub
          font-style: normal
          font-weight: 400
          font-size: 14px
          line-height: 16px
          display: flex
          align-items: center
          letter-spacing: 0.5px
          color: $grey-text
    .button
      cursor: pointer
      background-color: $primary-light
      color: $blue-primary
      margin-top: 8px
      padding: 10px
      border: none
      border-radius: 12px
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      text-align: center
      letter-spacing: 0.5px
      &:hover
        background-color: $blue-primary
        color: $white-snow