// global

@import "./styles/index"
@import "./styles/global.sass"

.App
  display: flex
  flex: 1
  min-height: 100vh
  overflow: auto
  justify-content: center
  background: $background-color
