@import "../../styles/vars.sass"

.transaction-modal
  position: fixed
  flex: 1
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  align-self: center
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.7)
  padding: 16px
  z-index: 100
  .modal
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    background-color: $white
    border-radius: 24px
    padding: 16px 16px 84px
    width: 100%
    max-width: 600px
    overflow: hidden
    .image
      width: 90px
      height: 90px
    .title
      font-style: normal
      font-weight: 500
      font-size: 16px
      line-height: 22px
      display: flex
      color: $blue-ocean
      margin-top: 16px
      align-self: flex-start
    .steps
      display: flex
      flex-direction: row
      align-items: center
      justify-content: flex-start
      width: 100%
      margin-top: 12px
      .step
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 22px
        color: $grey-text
      .value
        font-style: normal
        font-weight: 500
        font-size: 16px
        line-height: 22px
        color: $blue-ocean
        margin-left: 4px
    .button
      background-color: rgba($blue-primary, 0.1)
      color: $blue-primary
      font-style: normal
      font-weight: 500
      font-size: 14px
      letter-spacing: 0.5px
      padding: 12px
      border-radius: 12px
      position: absolute
      bottom: 16px
      left: 16px
      right: 16px
      &:hover
        background-color: $blue-primary
        color: $white
    .circle-close
      position: absolute
      top: 16px
      right: 16px
