@import "src/styles/vars"

.connection-not-supported
  position: fixed
  flex: 1
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  align-self: center
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.7)
  padding: 24px
  z-index: 110
  .modal
    position: relative
    display: flex
    flex-direction: column
    background-color: $white
    border-radius: 24px
    padding: 24px
    width: 100%
    max-width: 600px
    .title
      font-style: normal
      font-weight: 400
      font-size: 24px
      line-height: 24px
      display: flex
      color: $black-text
      align-self: flex-start
      margin-top: 6px
    .message
      font-style: normal
      display: flex
      margin-top: 18px
      align-self: flex-start
      font-weight: 400
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.25px
      color: rgba($black-text, 0.6)
      margin-bottom: 30px
    .clear
      display: flex
      align-self: flex-end
      background-color: transparent
      color: $blue-primary
      font-style: normal
      font-weight: 500
      font-size: 16px
      letter-spacing: 0.5px
      padding: 12px
      border-radius: 12px
      &:hover
        background-color: $blue-primary
        color: $white
