@import "colors"

.MuiSnackbar-root
  @media(min-width: 600px)
    min-width: 400px

.MuiPaper-elevation
  border-top-left-radius: 16px !important
  border-top-right-radius: 16px !important

.MuiAlert-root
  border-color: transparent !important

.MuiButton-root
  text-transform: none !important
  border-radius: 12px !important
  font-size: 16px !important
  font-weight: 500 !important
  box-shadow: none !important
  &.MuiButton-containedPrimary
    background-color: $blue-primary
  &.MuiButton-textPrimary
    color: $blue-primary

.MuiDialog-root
  border-radius: 0 !important
  border-top-left-radius: 16px !important
  border-top-right-radius: 16px !important

  .MuiAccordionSummary-root
    .MuiAccordionSummary-content
      padding-left: 0
      padding-right: 0
      background-color: $white-snow

.MuiTabs-root
  .MuiTabs-scroller
    .MuiTabs-flexContainer
      border-bottom: solid 1px rgba(0, 24, 51, 0.1)
      display: flex
      justify-content: space-around
      .MuiButtonBase-root
        flex: 1
        font-style: normal
        font-weight: 400
        font-size: 14px
        line-height: 16px
        text-align: center
        letter-spacing: 0.5px
        color: $grey-text
        text-transform: none
        align-self: flex-end
        margin-bottom: 0
        padding-bottom: 0
        &.Mui-selected
          color: $blue-ocean
    .MuiTabs-indicator
      background-color: $blue-ocean
      height: 2px

.MuiFormControlLabel-root
  flex: 1
  flex-direction: row-reverse
  justify-content: space-between
  margin-left: 0
  margin-right: 0