@import "src/styles/vars"

.checkbox
  display: flex
  align-items: center
  justify-content: center
  background-color: white
  width: 21px
  height: 21px
  border-radius: 21px
  border: solid 3px $grey-text
  position: absolute
  top: 12px
  right: 12px
  &.checked
    background-color: $blue-primary
    border-color: $blue-primary