@import "src/styles/vars"

.container
  height: 100%
  display: flex
  flex-direction: column
  width: 600px
  @include mq($until: tablet)
    width: 100%
  background-color: $white-snow
  position: relative
  padding: 16px
  .header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    margin-bottom: 16px
    .title
      font-style: normal
      font-weight: 500
      font-size: 20px
      line-height: 24px
      display: flex
      align-items: center
      letter-spacing: 0.5px
      color: $blue-ocean
    .wallet-connect
      cursor: pointer
      background-color: transparent
      color: $blue-ocean
      padding: 8px 12px
      border: $blue-ocean solid 2px
      border-radius: 16px
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      align-items: center
      justify-content: center
      text-align: center
      letter-spacing: 0.5px
      &:hover
        color: $grey-text
        border: $grey-text solid 2px
  .tabs
    display: flex
    margin-top: 4px
    .tabs-header
      display: flex
      flex: 1
      flex-direction: row
      align-items: center
      justify-content: space-between
    .tabs-icons
      display: flex
      flex-direction: row
      align-items: center
      .search
        height: 21px
        margin-left: 16px
        cursor: pointer
      .filter
        cursor: pointer
        height: 21px
        margin-left: 16px
  .bar-content
    display: flex
    flex-direction: row
    width: calc(100% - 10px)
    background-color: white
    padding: 16px
    border-radius: 8px
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1)
    vertical-align: center
    align-items: center
    color: $black-text
    position: relative
    .cross
      width: 14px
      height: 14px
    .title
      font-size: 14px
      padding-left: 16px
    .btn-donate
      position: absolute
      right: 16px

