@import "src/styles/vars"

.users
  display: flex
  flex-direction: row
  flex-wrap: wrap
  margin-top: 14px

.user-item-container
  position: relative
  display: flex
  flex: 0.5
  min-width: 50%

.user-item
  position: relative
  display: flex
  flex: 1 1
  min-width: 50%
  flex-direction: column
  background-color: $white
  border-radius: 14px
  margin: 8px 8px 12px
  justify-content: space-between
  .image
    border-radius: 14px
    min-height: 156px
    object-fit: cover
    cursor: pointer
    &.no-image
      background-color: $blue-light
      object-fit: contain
      padding: 10px
  .data-container
    position: absolute
    display: flex
    background: rgba(255, 255, 255, 0.4)
    backdrop-filter: blur(4px)
    bottom: 4px
    left: 4px
    border-radius: 8px
    padding: 6px
    width: calc(100% - 8px)
    justify-content: space-between
    align-items: center
    .column
      overflow: hidden
      .title
        font-style: normal
        font-weight: 500
        font-size: 12px
        line-height: 16px
        display: flex
        align-items: center
        letter-spacing: 0.5px
        color: $black-text
      .description
        font-style: normal
        font-weight: 400
        font-size: 10px
        line-height: 16px
        display: flex
        align-items: center
        letter-spacing: 0.5px
        color: $black-text
        flex: none
    .button
      cursor: pointer
      background-color: $grey-bg
      width: 36px
      height: 36px
      &:hover
        background-color: $white-snow
      &.disabled
        background-color: $primary-light
        color: $blue-primary
