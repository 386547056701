$main-color: #85e8ff
$blue-light: #EDF4FC
$blue-ozone: #F5FAFF
$background-color: #F5FAFF
$hover-color: rgb(133 232 255 / 60%)
$black-text: #001833
$blue-primary: #0066DA
$blue-ocean: #001833
$grey-text: #9B9B9B
$grey-bg: #F2F3F6
$grey-divider: #E0E0E0
$grey-light: #f8f8f8
$white-snow: #F5FAFF
$white: #FFFFFF
$black-diamond: #0B1624
$black-heart: #000000
$primary-light: rgba(0, 102, 218, 0.1)
$green-mile: #4CAF50
$orange-warning: #FD9900
$red-alert: #F44336
