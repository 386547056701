@import "src/styles/vars"

.donations
  position: relative
  display: flex
  flex-direction: column
  height: 100%
  width: 600px
  padding: 16px
  background-color: $blue-ozone
  @include mq($until: tablet)
    width: 100%
  .content
    display: flex
    width: 100%
  .donations-balance-container
    display: flex
    flex-direction: column
    .title
      color: $blue-ocean
      font-style: normal
      font-weight: 700
      font-size: 32px
      line-height: 38px
      letter-spacing: 0.5px
    .sub-title
      color: $grey-text
      font-style: normal
      font-weight: 500
      font-size: 15px
      line-height: 16px
      letter-spacing: 0.5px
      margin-top: 4px